import { ImageLink } from "../../ui/image";
import { Button } from "../../button/button";
import "./trailer-block-item.scss";
import { TrailersBlocksTrailer } from "../trailers-blocks";
import { useNavigate } from "react-router-dom";

export interface TrailerBlockItemProps {
    trailer: TrailersBlocksTrailer;
}

export const TrailersBlockItem = ({trailer}: TrailerBlockItemProps) => {
    const navigate = useNavigate();

    return (
        <div className="trailer-block-item">
            <div className="trailer-block-item__image-container">
                <ImageLink name={trailer.img} alt="Wheeled Office Trailers" />
            </div>
            <div className="trailer-block-item__content">
                <h3 className="trailer-block-item__title">{trailer.title} <br/> Trailers</h3>
                <div className="trailer-block-item__description">{trailer.title} Trailers</div>
                <Button prefImgPosition='right'
                        prefImgName='arrow-right'
                        className="trailer-block-item__button"
                        onClick={() => navigate(trailer.link)}
                >See more</Button>
            </div>
        </div>
    )
}
