import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/button/button";
import "./home-header.scss";
import "./modal-style.scss";
import { ClassAttributes, HTMLAttributes, useState } from "react";
import { Modal } from "react-overlays";
import { ImageLink } from "../../../components/ui/image";

export const HomeHeader = (): JSX.Element => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const renderBackdrop = (
    props: JSX.IntrinsicAttributes & ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement>,
  ) => <div className='backdrop' {...props} />;

  const onClickBookTrailer = (): void => {
    navigate("/book-office-trailer");
  };

  return (
      <>
          <div className='home-header'>
            <div className='container'>
              <div className='home-header__content-wrapper'>
              <h1 className='home-header__title'>
                Trailer rental and <br/> sales in Alberta
              </h1>
              <div className="home-header__description">
                <p className="home-header__text">
                  We respond on your request immediately.
                </p>
                <ul className="home-header__list">
                  <li className="home-header__list-item">Fast service and maintenance.</li>
                  <li className="home-header__list-item">Automated billing.</li>
                  <li className="home-header__list-item">Loyalty programs for regular customers.</li>
                  <li className="home-header__list-item">A wide selection of available wheeled and skid
                    trailers with accessories.</li>
                  <li className="home-header__list-item">Delivery of trailers within the region.</li>
                </ul>

                <p className="home-header__text home-header__text-space">Local for locals.</p>
                <div className="home-header__buttons-wrapper">
                  <Button color='primary-blue' onClick={onClickBookTrailer}>BOOK NOW</Button>
                  {/* <Button color='primary-blue' className='home-header__buttons-wrapper__button-contact' onClick={() => setShowModal(true)}>*/}
                  {/*  GET IN TOUCH*/}
                  {/* </Button>*/}
                </div>
              </div>
            </div>
            </div>
           <div className='home-header__image-wrapper'>
              <ImageLink name="home-trailer.webp" alt="Exel Trailers"></ImageLink>
           </div>
           <Modal className="modal" show={showModal} onHide={handleClose} renderBackdrop={renderBackdrop}>
            <div>
              <div className='modal-header'>
                <div className='modal-title'>GET IN TOUCH</div>
                <div>
                  <span className='close-button' onClick={handleClose}>
                    x
                  </span>
                </div>
              </div>
              <div className='modal-desc'>
                <iframe
                  title='Pipedrive Form'
                  src='https://webforms.pipedrive.com/f/c6F6JPumieVFQpbMoxQPv0neq0ELV2tTPfT9jlVFJuuGx4LE1wmDmZhPvvwQ9y8LsL'
                  width='100%'
                  height='600'
                  frameBorder='0'
                ></iframe>
              </div>
            </div>
          </Modal>
          </div>
      </>
  );
};
