import { ImageLink } from "../../../components/ui/image";
import "./home-map.scss";
import { SvgIcon } from "../../../components/svg-icon/svg-icon";

export const HomeMap = () => {
    return (
        <div className="home-map">
            <div className="container">
                <div className="home-map__container">
                    <div className="home-map__image-wrapper">
                        <ImageLink name="map.webp" alt="Calgary"/>
                    </div>
                    <div className="home-map__content">
                        <h2 className="home-map__content-title">Our locations</h2>
                        <div className="home-map__locations">
                            <div>
                                <div className="home-map__location">
                                    CALGARY
                                    <SvgIcon name="arrow-right" />
                                </div>
                                <div className="home-map__location">
                                    VANCOUVER
                                    <SvgIcon name="arrow-right" />
                                </div>
                                <div className="home-map__location">
                                    LETHBRIDGE
                                    <SvgIcon name="arrow-right" />
                                </div>
                            </div>
                            <div>
                                <div className="home-map__location">
                                    EDMONTON
                                    <SvgIcon name="arrow-right" />
                                </div>
                                <div className="home-map__location">
                                    RED DEER
                                    <SvgIcon name="arrow-right" />
                                </div>
                                <div className="home-map__location">
                                    SURREY
                                    <SvgIcon name="arrow-right" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
