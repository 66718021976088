import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import { homeOfficeTrailers } from "../../config/home-office-trailers";
import { ToastContext } from "../../context/toast/toast.context";
import { PriceList, Trailer } from "../../interfaces";
import { httpClient } from "../../utils/http-client";
import { HomeHeader } from "./home-header/home-header";
import { HomeWhyChoose } from "./why-choose/why-choose";
import SEO from "../../components/seo/seo";
import FAQ from "./FAQ/FAQ";
import { TrailersBlocks } from "../../components/trailers-blocks/trailers-blocks";
import { HomeMap } from "./home-map/home-map";


export const Home = (): JSX.Element => {
  const [trailers, setTrailers] = useState<Trailer[]>(homeOfficeTrailers.slice(0, 5));
  const { addToast } = useContext(ToastContext);

  useEffect(() => {
    const officeTrailers = [...trailers];
    officeTrailers.map((trailer) => {
      if (trailer.name === "8’x26’ Office Trailer with toilet") {
        trailer.prices = undefined;
      }
      return trailer;
    });
    setTrailers(officeTrailers);
    fetchPrices();
  }, [setTrailers]);

  const fetchPrices = (): void => {
    httpClient
      .get<PriceList[]>(`client/trailers/prices?trailerType=${"OFFICE"}`)
      .then((response: AxiosResponse) => response.data)
      .then((priceList: PriceList[]) => {
        const updatedPrices = priceList?.map((priceList) => {
          let size = priceList?.dimensions?.split("OFFICE_").join("");
          size = size.replace("_X_", "x");
          return { size, priceList };
        });
        onHandlePriceOfTrailers(updatedPrices);
      })
      .catch((error: Error) => {
        addToast({
          text: error?.message,
          type: "error",
        });
      });
  };

  const onHandlePriceOfTrailers = (updatedPrices: { size: string; priceList: PriceList }[]): void => {
    const activeTrailers = [...trailers];
    activeTrailers.map((trailer) => {
      updatedPrices.forEach((price) => {
        if (trailer?.name) {
          const name = trailer?.name?.split(" ");
          name[0] = name[0].replaceAll("’", "");
          if (name[0] === price.size) {
            trailer.cost = `$ ${price.priceList.monthlyPrice} per month`;
            if (trailer.prices) {
              trailer.prices.map((item) => {
                if (item.type === "per day") {
                  item.price = price.priceList.onePrice;
                }
                if (item.type === "per week") {
                  item.price = price.priceList.weeklyPrice;
                }
                if (item.type === "per month") {
                  item.price = price.priceList.monthlyPrice;
                }
              });
            }
          }
        }
      });
      return trailer;
    });
    setTrailers(activeTrailers);
  };

  return (
    <div className='home'>
      <SEO
          title={"Office Trailers for Sale and Rent Alberta - EXCEL TRAILERS"}
          description={"Discover top-quality office trailers for sale and rent in Alberta. Perfect for job sites, temporary offices, or remote workspaces. Affordable prices and flexible rental terms."}/>
      <HomeHeader />
      <TrailersBlocks />
      <HomeWhyChoose />
      <HomeMap />
      <FAQ />
    </div>
  );
};
