import { useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../button/button";
import SvgIcon from "../../svg-icon/svg-icon";
import { Logo } from "../../ui/logo";
import "./footer.scss";

export const Footer = (): JSX.Element => {
  const navigate = useNavigate();
  const [currentYear] = useState(new Date().getFullYear());

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  const navigateToContactUs = (): void => {
    navigate("/contact-us");
  };

  return (
    <div className='footer'>
      <div className='container'>
        <div className='footer__questions footer__questions--mobile'>
          <div className='footer__questions-title'>Have any questions?</div>
          <Button color='primary' className='footer__questions-button' onClick={navigateToContactUs}>
            Contact Us
          </Button>
        </div>
        <div className='footer__container'>
          <div className='footer__excel-trailers'>
            <p className='footer__title'>excel trailers ltd.</p>
            <div className='footer__link-items'>
              <a
                href='http://maps.google.com/?q=7320 40 St NE, Calgary, AB T3J 4E6, Canada'
                target='_blank'
                className='footer__link-item'
                rel='noreferrer'
              >
                Calgary, Alberta
              </a>
              <a href='tel:4034651277' target='_blank' className='footer__link-item' rel='noreferrer'>
                403 465 1277
              </a>
              <a href='mailto:sales@exceltrailers.ca' target='_blank' className='footer__link-item' rel='noreferrer'>
                sales@exceltrailers.ca
              </a>
            </div>
          </div>
          <div className='footer__product'>
            <p className='footer__title'>Product</p>
            <div className='footer__link-items'>
              <Link to='/office-trailers' className='footer__link-item'>
                Office trailers
              </Link>
              <Link to='/flat-deck-trailers' className='footer__link-item'>
                Flat Desk trailers
              </Link>
              <Link to='/sale-new-trailers' className='footer__link-item'>
                New trailers
              </Link>
              <Link to='/sale-used-trailers' className='footer__link-item'>
                Used trailers
              </Link>
            </div>
          </div>
          <div className='footer__company'>
            <p className='footer__title'>Company</p>
            <div className='footer__link-items'>
              <Link to='/' className='footer__link-item'>
                Home
              </Link>
              <Link to='/office-trailers' className='footer__link-item'>
                Rent
              </Link>
              <Link to='/sale-new-trailers' className='footer__link-item'>
                Sales
              </Link>
              <Link to='/blog' className='footer__link-item'>
                Blog
              </Link>
              <Link to='/#faq' className='footer__link-item'>
                FAQ
              </Link>
              <Link to='/contact-us' className='footer__link-item'>
                Contact Us
              </Link>
            </div>
          </div>
          <div className='footer__questions footer__questions--desktop'>
            <div className='footer__questions-title'>Have any questions?</div>
            <Button color='primary' className='footer__questions-button' onClick={navigateToContactUs}>
              Contact Us
            </Button>
          </div>
        </div>
        <hr className='footer__divider' />
        <div className='footer__bottom'>
          <div className='footer__logo'>
            <Link to='/'>
              <Logo width={73} height={65}/>
            </Link>
          </div>
          <div className="footer__contacts-wrapper">
            <div className="footer__contacts">
            <div className="footer__contacts-phone">
              <a href="tel:4034651277" target="_blank" className="footer__contacts-link" rel="noreferrer">
                <SvgIcon name="phone" className="header__icon-svg"/>
                403 465 1277
              </a>
            </div>
            <div className="footer__contacts-mail">
              <a href="mailto:sales@exceltrailers.ca" target="_blank" className="footer__contacts-link" rel="noreferrer">
                <SvgIcon name="mail" className="header__icon-svg"/>
                sales@exceltrailers.ca
              </a>
            </div>
          </div>
            <div className="footer__list">
            <Link to="/privacy-policy" className="footer__list-item">
              Terms
            </Link>
            <Link to="/privacy-policy" className="footer__list-item">
              Privacy
            </Link>
            <Link to="/privacy-policy" className="footer__list-item">
              Cookies
            </Link>
          </div>
          </div>
          <div className="footer__socials">
            <a
                href="https://www.youtube.com/channel/UCNIed5tt5_qPkjbE7AM57ZA"
                target="_blank"
                className='footer__link-item'
                aria-label='View more about Excel Trailers on Youtube'
                rel='noreferrer'
            >
              <SvgIcon name='youtube' className='footer__svg-icon'/>
            </a>
            <a
                href='https://www.linkedin.com/company/excel-trailers/?viewAsMember=true'
                target='_blank'
                className='footer__link-item'
                aria-label='Read more about Excel Trailers on linkedin'
                rel='noreferrer'
            >
              <SvgIcon name='linkedin' className='footer__svg-icon'/>
            </a>
            <a
                href='https://www.facebook.com/exceltrailers'
                target='_blank'
                className='footer__link-item'
                aria-label='Read more about Excel Trailers on facebook'
                rel='noreferrer'
            >
              <SvgIcon name='facebook' className='footer__svg-icon'/>
            </a>
            <a
                href='https://instagram.com/exceltrailers?igshid=YmMyMTA2M2Y='
                target='_blank'
                className='footer__link-item'
                aria-label='Read more about Excel Trailers on instagram'
                rel='noreferrer'
            >
              <SvgIcon name='instagram' className='footer__svg-icon'/>
            </a>
          </div>
        </div>
        <div className='footer__years'>
          2015 - {currentYear}
        </div>
      </div>
    </div>
  );
};
