import { Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import { Layout } from "./components/layout/layout";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  AboutUsPage,
  Accessories,
  ArticleExploringTheVariedTerrainOfTrailersPage,
  ArticleHowToHookUpAnOfficeTrailerToTheVehiclePage,
  ArticleOptimizingTheWorkspaceInOfficeTrailersForBuildersPage,
  ArticleWhatIsAnOfficeTrailerPage,
  ArticleFlatDeckRentOrBuyPage,
  ArticleHowToLightEmpireHeaterPage,
  BlogPage,
  BookOfficeTrailer,
  BookTrailer,
  BuyTrailer,
  ContactUs,
  OfficeTrailerDetails,
  PrivacyPolicy,
  RefrigeratorDetails,
  Refrigerators,
  Rent,
  RentFlatDeckTrailers,
  RentOfficeTrailers,
  SaleNewOfficeTrailers,
  SaleNewTrailerDetails,
  SaleUsedOfficeTrailers,
  SaleUsedTrailerDetails,
  SendCreditApplication,
  SuccessPage,
  SignInPage,
  SignUpPage,
  ForgotPasswordPage,
  NewPasswordPage,
  RestoreCodePage,
  ProfilePage,
  PersonalInfoPage,
  OrdersPage,
  CouponsPage,
} from "./routes";
import { Home } from "./pages/home/home";
import { HelmetProvider } from "react-helmet-async";
import { RemoveTrailingSlash } from "./components/remove-trailing-slash/remove-trailing-slash";
import AuthProvider from "./context/auth-provider";
import PrivateRoute from "./hooks/private-route";
import PublicRoute from "./hooks/public-route";
import { GoogleOAuthProvider } from "@react-oauth/google";

const TRACKING_ID = "UA-131746487-1";
const tagManagerArgs = {
  gtmId: "GTM-WLMF6HG",
};

function App() {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <HelmetProvider>
      <GoogleOAuthProvider clientId={"290426214625-eech0se9au6ipg37qcapa8sa82u5aota.apps.googleusercontent.com"}>
        <AuthProvider>
          <Layout>
            <RemoveTrailingSlash />
            <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/rent' element={<Rent />} />
            <Route path='/office-trailers' element={<RentOfficeTrailers />} />
            <Route path='/flat-deck-trailers' element={<RentFlatDeckTrailers />} />
            <Route path='/refrigerators' element={<Refrigerators />} />
            <Route path='/accessories' element={<Accessories />} />
            <Route path='/sale-new-trailers' element={<SaleNewOfficeTrailers />} />
            <Route path='/sale-used-trailers' element={<SaleUsedOfficeTrailers />} />
            <Route path='/office-trailers/:id' element={<OfficeTrailerDetails />} />
            <Route path='/trailer/:id' element={<OfficeTrailerDetails />} />
            <Route path='/sale-new-trailer/:id' element={<SaleNewTrailerDetails />} />
            <Route path='/sale-used-trailers/:id' element={<SaleUsedTrailerDetails />} />
            <Route path='/refrigerator/:id' element={<RefrigeratorDetails />} />
            <Route path='/book-trailer' element={<BookTrailer />} />
            <Route path='/book-trailer/:id' element={<BookTrailer />} />
            <Route path='/book-office-trailer' element={<BookOfficeTrailer />} />
            <Route path='/book-office-trailer/:id' element={<BookOfficeTrailer />} />
            <Route path='/buy-trailer' element={<BuyTrailer />} />
            <Route path='/buy-trailer/:id' element={<BuyTrailer />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/send-credit-application' element={<SendCreditApplication />} />
            <Route path='/success-page' element={<SuccessPage />} />
            <Route path='/about-us' element={<AboutUsPage />} />
            <Route path='/blog' element={<BlogPage />} />
            <Route path='/blog/how-to-hook-up-an-office-trailer-to-the-vehicle' element={<ArticleHowToHookUpAnOfficeTrailerToTheVehiclePage />} />
            <Route path='/blog/what-is-an-office-trailer' element={<ArticleWhatIsAnOfficeTrailerPage />} />
            <Route path='/blog/exploring-the-varied-terrain-of-trailers-flat-deck-vs-deckover-trailers' element={<ArticleExploringTheVariedTerrainOfTrailersPage />} />
            <Route path='/blog/optimizing-the-workspace-in-office-trailers-for-builders'
                   element={<ArticleOptimizingTheWorkspaceInOfficeTrailersForBuildersPage />} />
            <Route path='/blog/flat-deck-rent-or-buy' element={<ArticleFlatDeckRentOrBuyPage />} />
            <Route path='/blog/light-empire-heater' element={<ArticleHowToLightEmpireHeaterPage />} />

            <Route element={<PublicRoute />}>
              <Route path='/sign-in' element={<SignInPage />} />
              <Route path='/sign-up' element={<SignUpPage />} />
              <Route path='/forgot-password' element={<ForgotPasswordPage />} />
              <Route path='/restore-code' element={<RestoreCodePage />} />
              <Route path='/new-password' element={<NewPasswordPage />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path='/profile' element={<ProfilePage />} >
                <Route index element={<PersonalInfoPage />} />
                <Route path='' element={<PersonalInfoPage />} />
                <Route path='orders' element={<OrdersPage />} />
                <Route path='coupons' element={<CouponsPage />} />
              </Route>
            </Route>

            <Route path='*' element={<Navigate to='/' replace />} />
          </Routes>
          </Layout>
        </AuthProvider>
      </GoogleOAuthProvider>
    </HelmetProvider>
  );
}

export default App;
